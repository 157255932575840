import React from 'react'
const LastBanner = () => {
  return (
    <>
    
        <div className="container m-auto h-100 ">
       
        <div className="row">
          <div className="col-12">
          <div className="table-responsive">
              <table className="table table-bordered border-black">
                <thead>
                  <tr>
                    <th  colSpan="6" className="contact-header text-white text-center">
                      SEBI Registration Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Registered Name</td>
                    <td>BRIGHTER MIND EQUITY ADVISOR PRIVATE LIMITED</td>
                    
                  </tr>
                  <tr>
                    <td>Type of Registration</td>
                    <td>Non-Individual Investment Advisor</td>
                    
                  </tr>
                  <tr>
                    <td>Registration No.</td>
                    <td> INA100016363</td>
                    
                  </tr>
                  <tr>
                    <td >Validity</td>
                    <td >November 08, 2021 Perpetual</td>
                    
                  </tr>
                  <tr>
                    <td>SEBI Regional Office Address</td>
                    <td>NBCC Complex, Office Tower-1, 8th Floor, Plate B, East Kidwai Nagar, New Delhi - 110023</td>
                    
                  </tr>
                  <tr>
                    <td></td>
                    <td>Email ID(Principal Officer):shashikant@brightermind.in <br />
                    Telephone(Principal Officer): 9911 005 770
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
    
    </>
  )
}

export default LastBanner