import React from 'react'
import { SiRazorpay } from "react-icons/si";
import '../NavBar/navbar.css'

const PaymentModal = ({setShowPayment, displayRazorpay, setshowPaymentDetails}) => {

const handleClick = () => {
  setShowPayment(false)
  setshowPaymentDetails(true)
}

  return (
    <>
    <div className="modal-wrapper">
   
   <div className="logout-confirm" style={{width: "400px"}}>
       <div className="body">
           <span><SiRazorpay /></span>
           <p className='text-center'>Finish payment to continue</p>
       </div>
       <div className="bottom-buttons">
           <button className='no' onClick={() => setShowPayment(false)}>Close</button>
           <button className='yes' onClick={handleClick}>Pay Now</button>
       </div>
   </div>
      
   </div>
    </>
  )
}

export default PaymentModal