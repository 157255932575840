import React, { useContext, useState } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import "../Pages/Style/billsinvoice.css";
import BusinessPlanCard from './BusinessPlanCard'; 
import "../Pages/Style/dashboard.css";
import "../../App.css";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { SidebarContext } from '../../context/SidebarContext';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const BusinessPlan = () => {
const { isSiderbaropen } = useContext(SidebarContext);
const [showLogout, setShowLogout] = useState(false);
const [showBusinessCard, setShowBusinessCard] = useState(false)

const handleClick = () => {
  setShowBusinessCard(true)
}

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" BillsInvoice  min-vh-100 ">
          <div className="PortfolioReviewHeader mx-4 my-4 py-3 px-2">
            <h4 className='mb-0'>Design Business Plan</h4>
          </div>
          {
            !showBusinessCard ? (
           
          <div className="PortfolioReviewTable mx-4 my-4">
          <div className="card p-3">
            <form action="">
            <MDBTable
              hover
              striped
              style={{ backgroundColor: "RGB(255 255 255)", fontSize: "14px" }}
              className='verticle-table'
            >
              <MDBTableHead
                style={{ backgroundColor: "#5A4344", color: "white" }}
              >
                <tr>
                  <th scope="col" style={{width: "100px", maxwidth: "100px", minwidth: "100px"}}>Projected Client & Business</th>
                  <th scope="col" style={{width: "100px", maxwidth: "100px", minwidth: "100px"}}>Client per Family</th>
                  <th scope="col" style={{width: "100px", maxwidth: "100px", minwidth: "100px"}}>No. of Family</th>
                  <th scope="col" style={{width: "120px", maxwidth: "120px", minwidth: "120px"}}>Client growth per year (%)</th>
                  <th scope="col" style={{width: "120px", maxwidth: "120px", minwidth: "120px"}}>Average Business per Client (Rs)</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                      <tr>
                        <td>Equity</td>
                        <td><input type="text" value={1} /></td>
                        <td><input type="text" /></td>
                        <td><input type="text" value={'10%'} /></td>
                        <td><input type="text" /></td>
                      </tr>
              </MDBTableBody>
            </MDBTable>
            <button onClick={handleClick} className='float-end border-0' style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}>Calculate</button>
            </form>
          </div>
          </div>
             
            )
            : 
            (
          <div className="PortfolioReviewTable mx-4 my-4">
            <BusinessPlanCard  setShowBusinessCard={setShowBusinessCard}/>
          </div>
              
            )
          }
          </div>
          <Footer/>
          </div>
          </div>
          {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  )
}

export default BusinessPlan