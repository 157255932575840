import React from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
const BusinessPlanCard = ({setShowBusinessCard}) => {


  return (
    <>
     <div className="card p-3">
            <h5 className='mb-3'>Business Forcast</h5>
            <div>
            <MDBTable
              hover
              striped
              style={{ backgroundColor: "RGB(255 255 255)", fontSize: "14px" }}
              className='verticle-table'
            >
              <MDBTableHead
                style={{ backgroundColor: "#5A4344", color: "white" }}
              >
                <tr>
                  <th scope="col" >Year</th>
                  <th scope="col" >Year Opening Sale</th> 
                  <th scope="col" >Net Sale</th>
                  <th scope="col" >Year End Sale</th>
                  <th scope="col" >Total Revenue</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                      <tr>
                        <td>1</td>
                        <td>18</td>
                        <td>0</td>
                        <td>22</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>18</td>
                        <td>0</td>
                        <td>22</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>18</td>
                        <td>0</td>
                        <td>22</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>18</td>
                        <td>0</td>
                        <td>22</td>
                        <td>0</td>
                      </tr>
              </MDBTableBody>
            </MDBTable>
            <button onClick={() => setShowBusinessCard(false)} className='float-end border-0' style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}>Recalculate</button>
                      </div>
     </div>
    </>
  )
}

export default BusinessPlanCard