import React from "react";
import { RxFile } from "react-icons/rx";
import "../../Pages/Style/otpScreen.css";
import brandLogo from "../photos/Brighter-Mind-logo.png";
import brightermindSignature from "../photos/brighetrmind.png";
// import { FaCheck } from "react-icons/fa";
const Agreement = (props) => {
  const { signatureUrl } = props.formData;
  // console.log(signatureUrl);

  const handleEdit = (e) => {
    e.preventDefault();
    props.setShowFourthScreen(false);
    props.setShowOtpScreen(false);
    props.setShowFirstScreen(true);
  };

  // Handle OTP feilds Changes
  const handleOtpChange = (e, index) => {
    props.setOtp([...props.otp.map((d, idx) => (idx === index ? e.value : d))]);

    if (e.nextSibling) {
      e.nextSibling.focus();
    }
  };

  const getDayWithOrdinalSuffix = (day) => {
    const suffixes = ["st", "nd", "rd"];
    const specialCases = [11, 12, 13];

    const lastDigit = day % 10;
    const suffix = suffixes[lastDigit - 1] || "th";

    return specialCases.includes(day) ? `${day}th` : `${day}${suffix}`;
  };

  const currentDate = new Date();
  const day = getDayWithOrdinalSuffix(currentDate.getDate());
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const handleNext = () => {
    props.setShowFourthScreen(false)
    props.setShowFifthScreen(true)
  }

  return (
    <>
      <div className="modal-box">
        <article className="modal-container">
          <header className="modal-container-header">
            <span className="modal-container-title ">
              <RxFile className="file-img" />
              Agreement
            </span>
            <p className="text-danger mb-0 text-center">{props.feildError}</p>
          </header>
          <section className="modal-container-body rtf" style={{fontSize: "13px", textAlign: "justify"}}>
            <div className="pdf-logo text-center ">
              <img src={brandLogo} alt="brandLogo" width={120} />
            </div>
            <p style={{ textAlign: "center" }} className="agreement-heading">
              <strong>INVESTMENT ADVISORY SERVICES AGREEMENT</strong>
            </p>
            <p>
              This Investment Advisory Services Agreement (hereinafter referred
              to as the "Agreement") is made at/on
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {" "}
                {day}{" "}
              </span>
              this day of
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {" "}
                {month}{" "}
              </span>{" "}
              , {" "}
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {" "}
                {year}{" "}
              </span>{" "}
              by and between <strong>“BRIGHTER MIND EQUITY ADVISOR PRIVATE LIMITED”</strong>
              (hereinafter shall be referred as “Brighter Mind Equity Advisor”),
              a Private Limited Company, incorporated in India under the
              provisions of The Companies Act, 2013 and having its Registered
              office at <strong>
              “53-B, U.G.Floor, Vijay Block, Laxmi Nagar, New Delhi - 110092”, PAN No.AAJCB7499N
              </strong>,
            </p>
            <strong>AND</strong>
            <p>
              <strong>Mr. / Ms. / Mrs</strong>{" "}
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {props.name}
              </span>{" "}
              an individual / Karta of Hindu Undivided Family / partnership firm
              formed under the provisions of Indian Partnership Act / a company
              incorporated under the provisions of The Companies Act, 1956 / a
              Limited liability partnership formed and registered under the
              Limited Liability Partnership Act, 2008 having office / residing
              at{" "}
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {props.address}
              </span>
              , having PAN No{" "}
              <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {props.panNumber}
              </span>
              .
            </p>
           <p> WHEREAS</p>
            <p>
              1. <strong>Brighter Mind Equity Advisor Pvt. Ltd.</strong> is inter alia in the
              business of providing investment advice to individuals and
              corporates regarding investments in the Indian Capital Markets. It
              has obtained registration from SEBI under the SEBI (Investment
              Advisers) Regulations, 2013 and is duly authorized by SEBI to
              provide Investment Advisory Services within the purview of SEBI
              (Investment Advisers) Regulations, 2013, any amendments thereto
              from time to time vide Registration No.{" "}
              <strong>INA100016363</strong>
            </p>
            <p>
              2. The Client is desirous of appointing Brighter Mind Equity
              Advisor as an Investment Advisor for the purpose of receiving
              advisory services relating to investing in, purchasing, selling or
              otherwise dealing in securities or investment products, and advice
              on investment portfolio containing securities or investment
              products, whether written or oral or through any other means of
              communication for the benefit of the Client and shall include
              financial planning;
            </p>
            <p>
              3. The Client warrants that the Client is eligible to enter into
              this agreement with the Investment Advisor to avail of the
              services.
            </p>
            <p>
              NOW THEREFORE, In consideration of the covenants and conditions
              hereinafter set forth, both parties to the agreement agree as
              follows:
            </p>
            <hr />
            <strong>I. TERM</strong>
            <p>
              This Agreement shall come into effect from the date of execution
              and shall remain valid and binding upon the Parties, unless
              terminated by either party as mentioned in clause “9”.
            </p>
            <strong>2. DEFINITIONS</strong>
            <p>
              For the purpose of this agreement, the terms shall be construed
              accordingly, –
            </p>
            <p>
              a. “Act” means the Securities and Exchange Board of India Act,
              1992 (15 of 1992);
            </p>
            <p>
              b. “assets under advice” shall mean the aggregate net assets value
              of securities and investment products for which the investment
              adviser has rendered investment advice irrespective of whether the
              implementation services are provided by the investment advisor or
              conducted by the client directly or through other service
              providers;
            </p>
            <p>
              c. "Board" means the Securities and Exchange Board of India
              established under section 3 of the Act;
            </p>
            <p>
              d. “certificate” means a certificate of registration granted under
              these regulations;
            </p>
            <p>
              e. “company” means a Company incorporated under the Companies Act,
              2013 (Companies Act, 1956)
            </p>
            <p>
              f. “consideration” means any form of economic benefit including
              non-cash benefit received or receivable for providing investment
              advice;
            </p>
            <p>
              g. “family of Client” shall include individual Client, dependent
              spouse, dependent children and dependent parents as confirmed by
              the Client, at the time of entering into the agreement or at any
              subsequent amendments thereof; The dependent family members shall
              be those members whose assets on which investment advisory is
              sought/provided, originate from income of a single entity i.e.
              earning individual Client in the family.
            </p>
            <p>
              h. “financial planning” shall include analysis of clients’ current
              financial situation, identification of their financial goals, ad
              developing and recommending financial strategies to realize such
              goals;
            </p>
            <p>
              i. “investment advice” means advice relating to investing in,
              purchasing, selling or otherwise dealing in securities or
              investment products, and advice on investment portfolio containing
              securities or investment products, whether written, oral or
              through any other means of communication for the benefit of the
              Client and shall include financial planning:
            </p>
            <p>
              Provided that investment advice given through newspaper,
              magazines, any electronic or broadcasting or telecommunications
              medium, which is widely available to the public shall not be
              considered as investment advice for the purpose of these
              regulations;
            </p>

            <p>
              j. “investment adviser” means any person, who for consideration,
              is engaged in the business of providing investment advice to
              Clients or other persons or group of persons and includes any
              person who holds out himself as an investment adviser, by whatever
              name called’
            </p>
            <p>
              k. “persons associated with investment advice” shall mean any
              member occupying a similar status or performing a similar function
              irrespective of the nature of association with the investment
              adviser who is engaged in providing investment advisory services
              to the Clients of the investment adviser;
            </p>
            <p>
              l. “related-party” shall mean parties who are joined by a
              pre-existing business relationship or common interest;
            </p>
            <p>
              m. “SEBI” shall mean the Securities and Exchange Board of India
              established under Section 3 of the Securities and Exchange Board
              of India Act, 1992 (15 of 1992).
            </p>
            <hr />

            <strong>3. APPOINTMENT</strong>
            <p>
              Pursuant to valid and proper authority and in accordance with
              applicable law, the Client hereby appoints
              <strong>Brighter Mind Equity Advisor</strong> to render the
              services of Investment Advisers for the Term and{" "}
              <strong>Brighter Mind Equity Advisor</strong> agrees to such
              appointment of terms and conditions provided for herein.
            </p>
            <strong>4. FEES</strong>
            <p>
              a. The Client(s) shall pay the Investment Advisor fees for the
              services rendered as provided in Schedule I or as may be amended
              from time to time.
            </p>
            <p>
              b. The Client agrees that fees are to be paid in favor of
              “Brighter Mind Equity Advisor Pvt. Ltd.”.
            </p>

            <p>
              c. Upon calculations of fees due, Brighter Mind Equity Advisor
              shall issue an invoice with applicable additions of taxes/GST,
              etc. under the Act and amendments included thereafter to “the
              Client”, payment shall be effected within thirty (30) days of
              submission of said invoice in consideration to the applicable
              deductions of taxes under the Act and amendments included
              thereafter. Payment made by the Client will be confirmed by
              Brighter Mind Equity Advisor.
            </p>
            <p>
              d. All fees shall be paid or made in full by the Client without
              any counter claim / deductions, set off or withholding.
            </p>
            <strong>5. SCOPE OF SERVICES</strong>
            <p>
              a. Brighter Mind Equity Advisor role would be to deliver
              investment advisory services to the Client(s). Hence the role
              would be limited to advisory by carrying out portfolio review &
              financial planning based on risk profiling. Further, the client
              has complete discretion on timing and execution of the
              transaction(s).
            </p>
            <p>
              b. The general objective is to formulate and device investment
              philosophy to achieve long term growth of capital or generate
              income or both, by investing in assets, which generate reasonable
              return and to advice client in line with each client profile with
              regard to their risk tolerance levels and specific preferences or
              concerns and to advice clients in line with each client’s goals on
              the basis of risk tolerance levels and other constraints if any.
            </p>
            <p>
              c. Brighter Mind Equity Advisor shall ensure that risk profiling
              of client has been carried on periodical basis and client is
              obligated to provide such information which is necessary for
              carrying out risk profiling using industry recognized tool or such
              other questionnaire for carrying out risk profiling. The
              information derived from risk profiling shall be communicated to
              the client after risk assessment has been done. Further,
              investment advice shall be provided based on suitability and
              appropriateness based on client’s investment objectives, risk
              tolerance, experience and knowledge of understanding risk by
              Clients(s) and rationale shall be documented towards the same. The
              client shall be required to provide all information pertaining to
              their investment portfolio and the investment advice shall be
              provided based on the responses provided by client in the risk
              profiling and/or financial planning and/or information provided
              towards overall portfolio.
            </p>
            <p>
              d. Brighter Mind Equity Advisor role is limited to advising the
              Clients and in no event shall be held liable for any claims of
              losses or damages made by the Client in respect of amount invested
              ortransacted.
            </p>
            <p>
              e. Brighter Mind Equity Advisor does not intend to provide legal,
              accounting, tax or specific Investment advice. If such advice is
              required, the services of a competent Professional advisor should
              be sought.
            </p>
            <p>
              f. Any information / content in Brighter Mind Equity Advisor
              brochure or any other material or otherwise communicated by
              Brighter Mind Equity Advisor, shall not be treated as substitute
              for necessary review for validation at the client(s) end.
            </p>
            <p>
              g. Brighter Mind Equity Advisor may also deal with Authorized
              Persons or authorized agency in respect of this agreement and such
              other Authorized Persons or authorized agency as and when notified
              to do so by theclient.
            </p>
            <p>
              h. Brighter Mind Equity Advisor shall at its sole discretion do
              all other acts as it deems necessary to achieve investment
              objective of client and for fulfillment of any other objective of
              this agreement.
            </p>
            <strong>
              6. DECLARATIONS, REPRESENTATIONS AND WARRANTIES OF BRIGHTER MIND
              EQUITY ADVISOR
            </strong>
            <p>
              a. Brighter Mind Equity Advisor represents that it has obtained a
              certificate of registration from Securities and Exchange Board of
              India under the SEBI (Investment Advisers) Regulations, 2013 and
              the registration number is <strong>INA100016363</strong>
            </p>
            <p>
              b. Brighter Mind Equity Advisor represents that it shall ensure
              that in case of any conflict of interest of investment advisory
              activities with other activities, such conflict of interest shall
              be disclosed to theclient.
            </p>
            <p>
              c. Investment products advised by Brighter Mind Equity Advisor
              including any financial and nonfinancial product such as
              securities (as defined under Section 2(h) of the Securities
              Contacts (Regulation) Act, 1956 including any amendments
              thereafter) or financial instrument purchased are subject to
              investment risk, liquidity risk, default risk, country risk,
              foreign exchange risk, interest rate risk, political risk, market
              risk including the possible loss of principal amount invested
              (details in Schedule 2). Past results or performance are not a
              guarantee to future performance. Yields or funds past performance
              should not be considered as indication or guarantee to future
              yield or result.
            </p>

            <p>
              d. Brighter Mind Equity Advisor shall maintain arm’s length
              relationship between its activities as investment adviser and
              distribution or execution services offered by its holding company.
            </p>
            <strong>
              7. DECLARATIONS, REPRESENTATIONS AND WARRANTIES OF CLIENT(S)
            </strong>
            <p>
              a. The Client(s) declares that the Client(s) has complied with and
              agrees to comply with all statutory formalities and guidelines
              issued by any regulatory authority to enable to enter into this
              arrangement with Brighter Mind Equity Advisor.
            </p>
            <p>
              b. Brighter Mind Equity Advisor shall have no liability for any
              such advice or representation made as it will be the
              responsibility of the Client(s) to make individual assessment.
            </p>
            <p>
              c. The Client(s) hereby agrees that the money being invested has
              been sourced through legitimate sources and subject to no
              violation in present, and, in future too there will no violation
              under the Prevention of Money Laundering Act, 2002 and subsequent
              amendments to the said act. The client hereby confirms and agrees
              that Investment Adviser reserves the right to report any
              suspicious transaction to the Director of Financial Intelligence
              Unit-India (FIU-IND), New Delhi or any other competent authority,
              after applying appropriate due diligence measures and believes
              that the transaction is suspicious in nature with the purview of
              applicable law and/or SEBI circulars/guidelines issued from time
              to time.
            </p>
            <p>
              d. The Client(s) hereby declares that the Client(s) have clear
              ownership of the money / wealth being advised through Brighter
              Mind Equity Advisor and there are no violations of any concerned
              statutory regulations.
            </p>
            <p>
              e. The Client(s) hereby declares that the Client(s) are duly
              authorized, eligible and competent to enter into business
              relationship with Brighter Mind Equity Advisor. Further, Client(s)
              declares that the client(s) has not been debarred / suspended or
              prohibited from carrying on its normal activities and more
              especially the service governed through this arrangement.
            </p>
            <p>
              f. The Client(s) hereby declares and represents that the Client(s)
              will, at all times during the existence of this arrangement with
              Brighter Mind Equity Advisor be the holder of all registrations
              and authorization required by any regulatory authority to carry on
              its activity.
            </p>
            <p>
              g. The Client(s) shall comply with all Applicable Laws including
              but not restricted to the SEBI (Prohibition of Insider Trading)
              Regulations, 2015, SEBI (Substantial Acquisition of Shares and
              Takeovers) Regulations, 2011, SEBI (Prohibition of Fraudulent and
              Unfair Trade Practices relating to Securities Markets)
              Regulations, 2003, The Prevention of Money Laundering Act, 2002,
              the Income Tax Act 1961 as may be enacted from time to time and
              the Investment Advisor shall not be responsible for any breach by
              the Client of such Applicable Laws.
            </p>
            <p>
              h. In the event of a change in the client’s constitution or
              identity by change of name or inter alia, events such as merger,
              amalgamation, liquidation, winding up, takeover, or change in
              management, as the case may be, during the term of this Agreement
              the client shall keep the Investment Advisor duly informed in
              writing of such change and provide such information to the
              Investment Advisor as the Investment Advisor may request in this
              regard. In such an event, the Investment Advisor shall seek advice
              or appropriate directions where required, under Applicable Laws
              with regard to the continuation of this Agreement.
            </p>

            <p>
              i. The Client(s) shall communicate the Risk Profile Questionnaire
              Report to Brighter Mind Equity Advisor and also required to
              provide all information pertaining to their investment portfolio
              or any change thereof. Brighter Mind Equity Advisor shall provide
              investment advice based on the responses provided by client(s) in
              the Risk Profiling and / or Financial Planning / or information
              provided towards their overall portfolio.
            </p>
            <p>
              j. The Client must declare his/ her status as Non-resident Indian
              or foreign nationals and FATCA declaration is mandatory to submit
              with all the other necessary documents and information.
            </p>
            <p>
              k. All foreign nationals and/or NRI Clients must disclose all the
              relevant information and related documents which are required as
              per the Rules, Regulations, guideline and all other applicable
              provisions being laid by RBI, SEBI, FEMA, PMLA Regulations and all
              competent Governing Bodies which are time being in force.
            </p>
            <strong>8. LIABILITY OF BRIGHTER MIND EQUITY ADVISOR</strong>
            <p>
              a. Without prejudice to what is stated above, Brighter Mind Equity
              Advisor shall not be liable for any or by reason of any loss or
              damage arising to the Client(s) or failure or delay in complying
              with the instruction of the Client(s), which is caused directly or
              indirectly by any event or circumstances beyond Brighter Mind
              Equity Advisor control.
            </p>
            <p>
              b. Brighter Mind Equity Advisor shall not be liable for any error
              or inaccuracies in any of the publicly available information that
              may be provided for Client(s) by Brighter Mind Equity Advisor.
            </p>
            <p>
              c. Without prejudice to provisions of Clause 9 hereunder, the
              Client, acknowledges and agrees that the Investment Manager (or
              its directors, officers, employees, agents, consultants or other
              representatives) shall not be responsible or liable for any
              direct, indirect, incidental, consequential, special, exemplary,
              punitive or any other damages (including loss of profits, loss of
              goodwill, business interruption etc.) for any error of judgment,
              mistake or for any loss suffered by the Client in connection with
              the Services or in respect of any matter to which the Agreement
              relates unless such damage or loss is finally judicially
              determined to have resulted primarily from the willful misfeasance
              and bad faith of the Investment Adviser.
            </p>
            <p>
              d. Neither the Investment Advisor nor any employee or agent of the
              Investment Advisor shall be liable for any advice or
              representation made by it under this Agreement; it will be the
              client’s responsibility to make an independent assessment.
            </p>
            <strong>9. CONFIDENTIALITY</strong>
            <p>
              a. Brighter Mind Equity Advisor and the Client(s) agree and
              undertake to keep confidential at all times the confidential
              information provided to the other party, details of this
              arrangement and all materials and other related documents
              pertaining to the transactions shared by the parties in connection
              with this agreement.
            </p>

            <p>
              b. However, Brighter Mind Equity Advisor will not publish,
              disclose or use any such confidential information unless required
              by law, order of a court of competent jurisdiction or by a
              regulatory authority or with specific permission of the client.
            </p>
            <p>
              c. Brighter Mind Equity Advisor may share the client(s)
              information for the purpose of taking expert / legal advice, if
              any, at the Client’s cost as mutually agreed between both the
              parties before acting upon.
            </p>
            <p>
              d. Neither Party hereto shall (except in the course of its duties
              hereunder or where required to comply with judicial,
              quasi-judicial, regulatory or statutory requirements) disclose to
              such third party (as may be directed by such regulatory/statutory
              authority) which disclosure shall be no more extensive than is
              usual or necessary to meet the requirements imposed upon the Party
              making such disclosure any information relating to Client or any
              investors or the affairs of the other Party (including information
              about the customers of either Party) and the arrangements
              contemplated by this Agreement or the contents of this Agreement
              unless authorized in writing by such other Party, as the case may
              be, and shall prevent any such prohibited disclosures as aforesaid
              whether by any of its officers, employees or agents.
            </p>
            <p className="ms-3">
              The receiving Party shall not be liable for disclosure or use of
              any Confidential information if:
            </p>
            <p className="ms-3">
              i. information which now is or hereafter comes into the public
              domain, otherwise than by reason of a breach of this Agreement; or
            </p>
            <p className="ms-3">
              ii. information made available to the receiving Party from other
              sources without obligation of confidentiality; or
            </p>
            <p className="ms-3">
              iii. Information that is already in the possession of or
              independently developed by the receiving Party.
            </p>
            <p className="ms-3">
              iv. Information which becomes known to the receiving Party prior
              to such disclosure without similar restrictions from a source
              other than the disclosing Party, as evidenced by written records.
            </p>
            <strong>10. TERMINATION</strong>
            <p>
              Either party may terminate this agreement at any time, by giving
              the other party thirty (30) days prior written notice. Upon
              termination by either party all outstanding fees in accordance to
              this agreement due to Brighter Mind Equity Advisor at that time
              shall be settled in full within sixty (60) days.
            </p>
            <p>
              This Agreement shall stand terminated forthwith on occurrence of
              the following events during the currency of this Agreement:
            </p>

            <p className="ms-3">
              a) Voluntary or compulsory termination of Investment Advisory
              Services by the Investment Adviser or the Client
            </p>
            <p className="ms-3">
              b) Insolvency, dissolution or winding up of the Client;
            </p>
            <p className="ms-3">
              c) Suspension or termination of the registration of the Investment
              Advisor as an Investment Advisor by SEBI or any other competent
              authority;
            </p>
            <p className="ms-3">
              d) Investment Advisor rendered incapable of rendering the
              Services; or
            </p>
            <p className="ms-3">
              e) Insolvency, dissolution or winding up of the Investment
              Advisor.
            </p>
            <p>
              All rights and obligations which have accrued or arisen prior to
              the termination of this Agreement shall continue to be governed by
              the provisions of this Agreement irrespective of any termination.
            </p>
            <p>
              Upon termination, the client or the heirs / successors / permitted
              assigns of the Client shall settle all dues owned by the Client
              under this Agreement towards fees, costs, charges and expenses.
            </p>

            <strong>11. DO & DON’T FOR CLIENT</strong>
            <p>
              a. The Client shall completely ignore any call from number other
              than the numbers mentioned in claiming to be from Brighter Mind
              Equity Advisor and shall report such cases on Brighter Mind Equity
              Advisor’s registered contact info.
            </p>
            <p>
              b. The client hereby agrees that the investment advice provided by
              Brighter Mind Equity Advisor is based on the risk profile of the
              Client; therefore, the client must not share the investment advice
              provided by Brighter Mind Equity Advisor to any other
              person/individual for any fee or free of cost under any
              circumstances. The client shall be fully responsible for the same.
            </p>
            <p>
              c. The Client understands that the Investment Adviser, in
              providing advice, may use or rely on the information provided by
              the Client and its Affiliates as well as any publicly available
              material and that the Investment Adviser does not assume
              responsibility for independent certification of any such
              information including without limitation any financial information
              forecasts or projections considered by the Investment Adviser in
              connection thereto.
            </p>
            <p>
              d. The Client shall endeavor to furnish to the Investment Adviser
              such information as the Investment Adviser may reasonably request
              in connection with the performance of its advisory services.
            </p>
            <p>
              e. The Client shall comply with all Applicable laws including but
              not restricted to the SEBI (Prohibition of Insider Trading)
              Regulations, 1992, SEBI (Substantial Acquisition of Shares and
              Takeovers) Regulations, 1997, SEBI (Prohibition of Fraudulent and
              Unfair Trade Practices Relating to Securities Markets)
              Regulations, 1995, Prevention of Money Laundering Act, 2002 ,
              Income-tax Act, 1961 as may be enacted from time to time and the
              Investment Adviser shall not be responsible for any breach by the
              Client of any such Applicable Laws.
            </p>
            <div className="para-column">
              <strong>12. INDEMNIFICATION</strong>
            </div>
            <p>
              In the event that either of the parties is found to be in breach
              of terms of this Agreement, such party shall at all times
              hereafter indemnify and keep the other party, its affiliates and
              their directors, officers, employees, representatives and agents
              (“Indemnified Party”) fully indemnified against all claims,
              demands, actions, proceedings, losses, damages, costs, charges,
              expenses, interests and disbursements of any nature whatsoever
              which the Indemnified Party may pay or incur or suffer or sustain
              or be liable to pay or incur or suffer or sustain as a result or
              consequence, direct or indirect, of such breach.
            </p>
            <strong>13. GOVERNING LAW AND JURISDICTION</strong>
            <p>
              a. This Agreement, the construction and enforcement of its terms
              and the interpretation of the right and duties of the Parties
              hereto shall be governed by the laws of land in rules and
              regulations existing in particular sectors, services, products, as
              the case may be. In case any clause is not in accordance with any
              laws or regulations in force, the latter will prevail over the
              clause forming part of this agreement. In case any clause is not
              in accordance with any laws or regulations in force, the latter
              will prevail over the clause forming part of this agreement.
            </p>
            <p>
              b. This Agreement shall be construed in accordance with the laws
              of India and shall be subject to the exclusive jurisdiction of the
              competent courts in Delhi only.
            </p>

            <strong>14. MISCELLANEOUS</strong>
            <p>
              a. This agreement constitutes the whole agreement between the
              parties and any alteration must be in writing and signed by both
              parties.
            </p>
            <p>
              b. The terms of this Agreement shall be binding upon and shall
              inure to the benefit of the Parties, their respective successors,
              successors-in-title, heirs and assigns. None of the provisions of
              this Agreement shall be for the benefit of or enforceable by any
              Person that is not a party hereto.
            </p>
            <p>
              c. No Party may assign its interest hereunder without the express
              written consent of the other Party.
            </p>
            <p>
              d. This Agreement embodies the entire agreement and understanding
              among the Parties hereto with respect to the subject matter hereof
              and supersedes all prior agreements and understandings relating to
              such subject matter.
            </p>
            <p>
              e. If any provision of this Agreement is held to be unenforceable
              under applicable law, the Parties agree to renegotiate such
              provision in good faith.
            </p>
            <p>
              f. If any provision of this Agreement or the application thereof
              to any person or circumstance is held to be invalid or
              unenforceable to any extent, the remainder of this Agreement and
              the application of such provision to persons or circumstances
              other than those as to which it is held invalid or unenforceable
              shall not be affected thereby, and each provision of this
              Agreement shall be valid and enforceable to the fullest extent
              permitted by law. Any invalid or unenforceable provision of this
              Agreement shall be replaced with a provision, which is valid and
              enforceable and most nearly reflects the original intent of the
              unenforceable provision.
            </p>
            <p>
              g. The Investment Advisor may record telephone conversations
              without use of a warning tone. Such records will be the Investment
              Advisor’s sole property and shall be accepted by the Client as
              conclusive evidence of any communication, advice or instructions
              to / from either party. Such records are kept for a certain period
              based on the in-house policy of the Investment Advisor and may be
              relied upon by the Investment Advisor as and when required.
            </p>
            <p>
              h. In case of any grievances, Client(s) may contact Compliance
              Officer having email IDbrightermindequity@gmail.com and if not
              satisfied with response, can lodge grievances with SEBI at{" "}
              <a
                className=" text-primary"
                href="https://scores.gov.in/scores/complaintRegister.html."
                target="_blank"
                rel="noreferrer"
              >
                https://scores.gov.in/scores/complaintRegister.html.
              </a>
            </p>
            <strong>15. OTHERS</strong>
            <p>
              a. No clause in this Agreement may be deleted or altered without
              the mutual consent of both parties and such consent for such
              deletion or alteration shall be obtained in writing by the
              otherparty.
            </p>
            <p>
              b. Request for amendment in any of the clauses of this Agreement
              shall be made in writing and be communicated to the other party
              within 7 days from the date of receipt of request for amending the
              clause and shall be agreed in writing on mutual consent between
              the parties.
            </p>
            <p>
              c. The amended clause shall form a part of this Agreement and
              constitutes a legal binding between the parties.
            </p>
            <p className=" fw-bold ">
              IN WITNESS WHEREOF the Parties hereto have set and subscribed
              their respective hands hereunto on the day and the year mentioned
              herein above.
            </p>
            <img src={signatureUrl} alt="Signature" width={100} height={45} />
                <p className="my-0">________________</p>
            <p className=" fw-bold mb-0">SIGNED SEALED AND DELIVERED</p>
            <p className="mt-0">by within named Client</p>

            <strong>SIGNED SEALED AND DELIVERED by</strong>
            <p className="my-0">
              BRIGHTER MIND EQUITY ADVISOR LIMITED <br />
              <span style={{ fontSize: "12px" }}>
                (through its constituted attorneys)
              </span>
            </p>
            <img src={brightermindSignature} alt="" width={100} height={45} />
                <p className="my-0">________________</p>
            
            <hr />
            <p className=" text-center ">SCHEDULE 1 - Schedule of Fees</p>

            <p>
              Client would be charged non-refundable fee at 2.5 % per annum of
              the Asset Under Advice (Investment Portfolio Value). The fee would
              be charged on half yearly basis. GST would be levied on the fees
              at applicable rates.
            </p>
            <p>
              Brighter Mind Equity Advisor Pvt. Ltd. shall provide the client an
              invoice for fee being charged and the client shall make the
              payment by way of account payee cheque issued in favour of –
              Brighter Mind Equity Advisor Private Limited or by way of
              electronic transfer.
            </p>
            <p>
              Bank account details for purpose of making electronic transfer are
              as follows:
            </p>
            <p>A/c name:- Brighter Mind Equity Advisor Private Limited</p>
            <p>A/c No:- 033005008280</p>
            <p>Bank name:- ICICI BANK LIMITED IFSC code:- ICIC0000330</p>
            <p>
            <img src={signatureUrl} alt="Signature" width={100} height={45} />
            <p className="my-0">________________</p>
              Client Signature
            </p>

            <hr />
            <p className=" text-center ">SCHEDULE 2 - Risk Factors</p>
            <p>
              The risks described herein is not an exhaustive list and the
              Potential Investors should read and understand them before
              investing in any securities and other financial products
              (hereinafter referred to as "Portfolio") based on the advice of
              Investment Adviser. Potential Investors should be aware that an
              investment in a Portfolio may be exposed to other risks of an
              exceptional nature from time to time. In addition, different risks
              may apply to different Portfolios. Prospective Investors should
              review the below risk factors in its entirety and take an informed
              decision before making an investment.
            </p>
            <p>
              <strong>1. Investment Risks</strong> <br />
              The Portfolio may be risky and the investors could lose all or
              part of their investment. Prices of a Portfolio could be volatile
              and a variety of other factors, that are inherently difficult to
              predict, such as domestic or international economic and political
              developments, global pandemics etc. may significantly affect the
              value of a Portfolio's investments. A Portfolio's performance over
              a period may not necessarily be indicative of the results that may
              be expected in future. Similarly, the past performance of the
              portfolio may not necessarily be indicative of the future results.
            </p>
            <p>
              <strong>2. Market Risks</strong> <br />
              The investments in securities market are subject to normal market
              fluctuations and other risks inherent to investing in such
              investments and there can be no assurance that any appreciation in
              value will occur. Prospective investors are advised that the value
              of investment and their income from it may go down as well as up.
              An investment should only be made by persons who have the ability
              to sustain a loss. The financial markets may be adversely affected
              as a result of geopolitical situations, or historically
              unprecedented events, which could diminish the value of
              investments.
            </p>
            <p>
              <strong>3. Liquidity risk</strong> <br />
              The liquidity of the Portfolio may be restricted by trading
              volumes and settlement periods. Delays and/or other problems in
              settlement of transactions could result in temporary periods when
              the investments comprising the Portfolio are un-invested and no
              return is earned thereon.
            </p>
            <p>
              <strong>4. Pricing and valuation Risk</strong> <br />
              For quoted investments, a valuation price can be obtained from an
              exchange or similarly verifiable source.
            </p>
            <p>
              <strong>
                5. Risks Associated with Investments in Small to Medium
                Capitalization Companies
              </strong>{" "}
              <br />
              Investment Adviser may recommend investment in the securities of
              companies with small-to medium-sized market capitalizations which
              may have lower liquidity and higher price volatility. While the
              Investment Adviser believes these investments often provide
              significant potential for appreciation, these securities,
              particularly smaller-capitalization securities, involve higher
              risks in some respects as compared to investments in securities of
              larger companies.
            </p>

            <p>
              <strong>6. Market disruption and geopolitical Risk</strong> <br />
              Various social and political tensions in India and around the
              world may contribute to increased market volatility, may have
              long-term effects on financial markets and may cause further
              economic uncertainties in India and worldwide.
            </p>
            <p>
              <strong>7. Political and economic risks</strong> <br />
              The Government of India has traditionally exercised, and continues
              to exercise, a significant influence over many aspects of the
              economy. There can be no assurance that existing government
              policies will be continued and a significant change in the
              government's policies in the future could affect business and
              economic conditions in India and could also adversely affect our
              business, prospects, financial condition and results of operation.
              Any political instability in India may adversely affect the Indian
              securities markets in general, which could also adversely affect
              the trading price of the Indian securities.
            </p>
            <p>
              <strong>8. Regulatory risk</strong> <br />
              The value and marketability of the investments may be affected by
              changes or developments in the legal and regulatory environment in
              India. The SEBI regulates the securities market in India and
              legislates from time to 8. Regulatory risk The value and
              marketability of the investments may be affected by changes or
              developments in the legal and regulatory environment in India. The
              SEBI regulates the securities market in India and legislates from
              time to
            </p>

            <p>
              <strong>9. Foreign Exchange Risk</strong> <br />
              Foreign exchange risk, also known as exchange rate risk, is the
              risk of financial impact due to exchange rate fluctuations
            </p>
            <p>
              <strong>10. Bankruptcy of investee companies</strong> <br />
              Bankruptcy risk, or insolvency risk, is the likelihood that a
              company will be unable to meet its debt obligations. Its the
              probability of a firm becoming insolvent due to its inability to
              service its debt. Consequently, your investments in such companies
              may be substantially impacted.
            </p>
            <p>
              <strong>11. Litigation risk</strong>
              Some investments may be governed by a complex series of legal
              documents and contracts. As a result, the risk of a dispute over
              interpretation or enforceability of the documentation and
              consequent costs and delays may be higher than for other types of
              investments. The Investee companies may be subject to material or
              protracted litigation, litigation expenses and the liability
              threatened or imposed could have a material adverse effect on your
              investments in such companies.
            </p>
            <p>
              <strong>
                12. No guarantee on Investment philosophy and potential to lose
                all the sum invested
              </strong>{" "}
              <br />
              Prospective investors should ensure that they understand the
              nature of such investment and the extent of their exposure to
              risk, that they have sufficient knowledge and experience and
              access to professional advisers to make their own legal, tax,
              accounting, and regulatory risks of investment in such Portfolios
              and that they consider the suitability of such an investment in
              the light of their own circumstances and financial condition. An
              investment in a Portfolio should not in itself be considered a
              balanced investment program, but rather is intended to provide
              diversification in a more complete investment portfolio.
              Investment advice is arrived at after using statistical methods,
              trading models, and quantitative research tools depend upon the
              assessment of business and economic trends. No assurance can be
              given of the accuracy of business assessment and subsequent
              investment advice.
            </p>

            <p>
              Prospective clients should review/study the above Risk factors
              carefully and in its entirety and shall not construe the contents
              hereof or regard the summaries contained herein as advice relating
              to legal, taxation, or financial / investment matters.
            </p>
            <p>
              Clients are advised to consult their own professional advisor(s)
              as to the legal, tax, financial or any other requirements or
              restrictions within their jurisdiction of nationality, residence,
              incorporation, domicile etc. relating to the subscription,
              gifting, acquisition, holding, disposal (sale or conversion into
              money) of portfolio and to the treatment of income (if any),
              capitalization, capital gains, any distribution and other tax
              consequences.
            </p>
           
           
            <div className="d-flex justify-content-between " style={{marginTop: "100px"}}>
              <div className="img-box d-flex  flex-column w-50">
                <img src={signatureUrl} alt="Signature" width={100} height={45} />
                <p>________________</p>
                <strong style={{ height: "40px" }}>
                Client Signature 
                </strong>
              </div>
              <div className="img-box d-flex  flex-column w-50 ">
                <img src={brightermindSignature} alt="" width={100} height={45} />
                <p>________________</p>
                <strong style={{ height: "40px" }}>
                Authorised Signatory  
                </strong>
              </div>
            </div>
          </section>
          <footer className="modal-container-footer">
            {props.showOtpScreen && (
              <form className="form-screen agreement-otp-screen">
                <div className="w-100">

                   <>
                <div className="title text-center">OTP</div>{" "}
                <div className="title text-center">Verification Code</div>{" "}
                <p className="text-danger">{props.otpError}</p>
                <p className="message">
                  We have sent a verification code to your mobile number
                </p>{" "}
                <div className="inputs text-center">
                  {props.otp.map((data, index) => {
                    return (
                      <input
                        required
                        type="text"
                        maxLength="1"
                        onChange={(e) => handleOtpChange(e.target, index)}
                        key={index}
                        value={data}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                </div>
                </>
               
                </div>
                <div className="w-100 d-flex justify-content-end">
                  {
                    !props.verified &&(
                  <button className="action me-2" onClick={props.resendOtp}>
                    RESEND
                  </button>
                    )
                  }
                  {props.verified ? (
                    <>
                     <button
                       className="action me-2"
                       onClick={handleNext}
                     >
                       Next
                     </button>
                
                    </>
                  
                   ) : (
                     <button
                       className="action me-2"
                       onClick={props.handleOtpSubmit}
                     >
                       Verify
                     </button>
                   )}
                </div>
              </form>
             )} 
            {!props.showOtpScreen && (
              <>
                <button className="button is-primary" onClick={handleEdit}>
                  Edit my Details
                </button>
                <button className="button " onClick={props.handleSubmit}>
                  Accept
                </button>
              </>
            )} 
          </footer>
        </article>
      </div>
    </>
  );
};

export default Agreement;
