import React, { useEffect, useState } from "react";
import "./Style/profiledetail.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { usePortfolioListQuery } from "../../redux/API/portfolioApi";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { formatIndianNumber } from "../../Constant/utils";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../Constant/Matcher";
import ReactPaginate from "react-paginate";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProfileDetail() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const clientCode = localStorage.getItem("clientCode");
  const { data: portfolioList, isLoading } = usePortfolioListQuery(clientCode);
  const [responseMap, setResponseMap] = useState({});
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const [sortField, setSortField] = useState("scripname");
  const [sortOrder, setSortOrder] = useState("asc");

  const sumOfAmounts = portfolioList?.data?.reduce(
    (sum, item) => sum + item?.avg * item.qty,
    0
  );

  const totalCurrentValue = portfolioList?.data?.reduce((acc, result) => {
    const currentprice = result?.stocklist?.currentprice;
    return acc + (currentprice ? currentprice * result.qty : 0);
  }, 0);
  const handleNavigate = (e, clientcode, scripname, scrip) => {
    e.preventDefault();
    navigate(`${clientcode}/${scripname}/${scrip}`);
  };

  const postDateForItem = (values, dates, payload, index) => {
    axios
      .post(`${baseUrl}client/cagr`, [values, dates, payload])
      .then((res) => {
        const { clientcagr, stockId } = res?.data;
        setResponseMap((prevMap) => ({
          ...prevMap,
          [stockId]: clientcagr,
        }));
      })
      .catch((error) => {
        // console.error(`Error for index ${index}:`, error);
      });
  };

  const processPortfolioData = () => {
    portfolioList?.data?.forEach((portfolioItem, index) => {
      const stockId = portfolioItem?._id?.stockid;
      const clientId = userId;
      const values = portfolioItem?.access_targets_out?.map((item) =>
        item.SellVal ? -item.SellVal : item.buyval
      );
      const dates = portfolioItem?.access_targets_out?.map((item) =>
        item.trasactiondate.slice(0, 10)
      );

      const lastValue = -(
        portfolioItem?.stocklist?.currentprice * portfolioItem?.qty
      );
      const today = moment().format("YYYY-MM-DD");

      values?.push(lastValue);
      dates?.push(today);

      const payload = {
        stockId: stockId,
        clientId: clientId,
      };

      postDateForItem(values, dates, payload, index);
    });
  };

  useEffect(() => {
    if (portfolioList?.data) {
      processPortfolioData();
    }
  }, [portfolioList?.data]);

  let displayIndex = 0;

  //Pagination
  const itemsPerPage = 20;
  const filteredData = portfolioList?.data.filter((item) => item?.qty > 0);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData?.slice(offset, offset + itemsPerPage);

  const handleSortFieldChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field is clicked
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // Change sort field and reset to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortData = (a, b) => {
    let aVal, bVal;
    // Determine field values based on the sortField
    if (sortField === "scripname") {
      aVal = a._id.scripname;
      bVal = b._id.scripname;
      return sortOrder === "asc"
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    } else if (sortField === "investedValue") {
      aVal = a.avg * a.qty;
      bVal = b.avg * b.qty;
    } else if(sortField === "avgPrice") {
      aVal = a.avg;
      bVal = b.avg
    }
    else if(sortField === "currentPrice"){
      aVal = a.avg;
      bVal = b.avg
    }
    else if (sortField === "currentValue") {
      aVal = a.stocklist?.currentprice * a.qty;
      bVal = b.stocklist?.currentprice * b.qty;
    } else if (sortField === "profit") {
      aVal = a.stocklist?.currentprice * a.qty - a.val;
      bVal = b.stocklist?.currentprice * b.qty - b.val;
    } else if (sortField === "profitper") {
      aVal =
        ((a.stocklist?.currentprice * a.qty - a.val) / (a.avg * a.qty)) * 100;
      bVal =
        ((b.stocklist?.currentprice * b.qty - b.val) / (b.avg * b.qty)) * 100;
    } else {
      return 0;
    }

    // Handle numeric sorting with sortOrder
    return sortOrder === "asc" ? aVal - bVal : bVal - aVal;
  };

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="ProfileDetail">
            <div
              className="UpperBtn my-4  mx-4"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <button className="download-btn mt-2 me-2">
                Download Investment Report
              </button>
            </div>
            <div className="row mx-4">
              <div className="col-md-6">
                <div className="card ProfileDetail-card  row-1">
                  <div className="mx-4">
                    <h5 className="card-title">Holding Value</h5>
                    <h2 className="Values">
                      {isLoading
                        ? "Loading..."
                        : ` ₹ ${formatIndianNumber(Math.round(sumOfAmounts))}`}
                    </h2>
                  </div>
                  <img
                    src={require("../Components/photos/Animation - 1697460328274.gif")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card ProfileDetail-card row-1">
                  <div className="mx-4">
                    <h5 className="card-title">Current Value</h5>
                    <h2 className="Values">
                      {isLoading
                        ? "Loading..."
                        : ` ₹ ${formatIndianNumber(
                            Math.round(totalCurrentValue)
                          )}`}
                    </h2>
                  </div>
                  <img
                    src={require("../Components/photos/Animation - 1697459940103.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              className="portfolio-detail-table my-5 col-11 flex-column align-items-lg-start position-relative"
              style={{ margin: "auto" }}
            >
              <div className="d-flex justify-content-between w-100">
                <p className="mt-2">
                  {portfolioList?.data?.filter((item) => item.qty > 0).length}{" "}
                  Record(s) Found
                </p>
              </div>
              <TableContainer
                component={Paper}
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                <Table
                  sx={{ minWidth: 700,  }}
                  aria-label="customized table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{textAlign: "center"}}>S.No</StyledTableCell>
                      <StyledTableCell
                       sx={{
                        width: "200px",
                        minWidth: "200px",
                        maxWidth: "200px",
                        textAlign: "center"
                      }}
                      >
                        Stock
                        <Link
                          className="mx-1"
                          onClick={() => handleSortFieldChange("scripname")}
                        >
                          <i
                            className={`fa-solid fa-arrow-up ${
                              sortField === "scripname" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </Link>
                        <Link
                          onClick={() => handleSortFieldChange("scripname")}
                        >
                          <i
                            className={`fa-solid fa-arrow-down ${
                              sortField === "scripname" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell sx={{textAlign: "center"}}>QTY</StyledTableCell>
                      <StyledTableCell
                       sx={{
                        width: "190px",
                        minWidth: "190px",
                        maxWidth: "190px",
                        textAlign: "center"
                      }}
                      >
                        Invested Value&nbsp;(₹)
                        <Link
                          className="mx-1"
                          onClick={() => handleSortFieldChange("investedValue")}
                        >
                          <i
                            className={`fa-solid fa-arrow-up ${
                              sortField === "investedValue" &&
                              sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </Link>
                        <Link
                          onClick={() => handleSortFieldChange("investedValue")}
                        >
                          <i
                            className={`fa-solid fa-arrow-down ${
                              sortField === "investedValue" &&
                              sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "130px",
                          minWidth: "130px",
                          maxWidth: "130px",
                          textAlign: "center"
                        }}
                      >
                        Avg. Price{" "}
                        
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "170px",
                          minWidth: "170px",
                          maxWidth: "170px",
                          textAlign: "center"
                        }}
                      >
                        {" "}
                        Current Price&nbsp;(₹)
                       
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "180px",
                          minWidth: "180px",
                          maxWidth: "180px",
                          textAlign: "center"
                        }}

                      >
                        {" "}
                        Current Value&nbsp;(₹){" "}
                        <Link className="mx-1" onClick={() => handleSortFieldChange("currentValue")}>
                          <i class={`fa-solid fa-arrow-up ${
                              sortField === "currentValue" &&
                              sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>{" "}
                        <Link onClick={() => handleSortFieldChange("currentValue")}>
                          <i class={`fa-solid fa-arrow-down ${
                              sortField === "currentValue" &&
                              sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "115px",
                          minWidth: "115px",
                          maxWidth: "115px",
                          textAlign: "center"
                        }}
                      >
                        Profilt{" "}
                        <Link className="mx-1" onClick={() => handleSortFieldChange("profit")}>
                          <i class={`fa-solid fa-arrow-up ${
                              sortField === "profit" &&
                              sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>{" "}
                        <Link onClick={() => handleSortFieldChange("profit")}>
                          <i class={`fa-solid fa-arrow-down ${
                              sortField === "profit" &&
                              sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "135px",
                          minWidth: "135px",
                          maxWidth: "135px",
                          textAlign: "center"
                        }}
                      >
                        Profilt %{" "}
                        <Link className="mx-1" onClick={() => handleSortFieldChange("profitper")}>
                          <i class={`fa-solid fa-arrow-up ${
                              sortField === "profitper" &&
                              sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>{" "}
                        <Link onClick={() => handleSortFieldChange("profitper")}>
                          <i class={`fa-solid fa-arrow-down ${
                              sortField === "profitper" &&
                              sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}></i>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell sx={{textAlign: "center"}}>CAGR</StyledTableCell>
                      <StyledTableCell sx={{textAlign: "center"}}>Transaction</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell className="text-center" colSpan={11}>
                          Loading...
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ) : (
                    <TableBody >
                      {filteredData &&
                        [...currentItems]
                          .sort(sortData)
                          ?.map((result, index) => {
                            displayIndex++;
                            const LTP = result?.stocklist?.currentprice;
                            const stockId = result?._id?.stockid;
                            const clientCagr =
                              responseMap[stockId] != null &&
                              typeof responseMap[stockId] === "number"
                                ? responseMap[stockId].toFixed(2)
                                : "";

                            return (
                              <StyledTableRow key={result?._id?.stockid} sx={{textAlign: "center"}}>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  <strong>{offset + displayIndex}</strong>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" sx={{textAlign: "center"}}>
                                  {result?._id.scripname}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {result?.qty ? result?.qty : "-"}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {result?.val
                                    ? formatIndianNumber(
                                        result?.avg * result?.qty
                                      )
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {formatIndianNumber(result?.avg)}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {formatIndianNumber(
                                    result?.stocklist?.currentprice
                                  )}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {formatIndianNumber(LTP * result?.qty)}
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign: "center"}}>
                                  {formatIndianNumber(
                                    result?.stocklist?.currentprice *
                                      result?.qty -
                                      result?.val
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                sx={{textAlign: "center"}}
                                  className={`${
                                    Math.abs(
                                      ((result?.stocklist?.currentprice *
                                        result?.qty -
                                        result?.val) /
                                        (result?.avg * result?.qty)) *
                                        100
                                    ) < 0.01
                                      ? "text-black"
                                      : ((result?.stocklist?.currentprice *
                                          result?.qty -
                                          result?.val) /
                                          (result?.avg * result?.qty)) *
                                          100 >
                                        0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {result?.stocklist?.currentprice &&
                                  result?.qty &&
                                  result?.val !== undefined &&
                                  result?.avg
                                    ? (
                                        ((result?.stocklist?.currentprice *
                                          result?.qty -
                                          result?.val) /
                                          (result?.avg * result?.qty)) *
                                        100
                                      ).toFixed(2)
                                    : "N/A"}
                                  %
                                </StyledTableCell>

                                <StyledTableCell sx={{textAlign: "center"}}>{clientCagr}%</StyledTableCell>

                                <StyledTableCell className="view-btn" sx={{textAlign: "center"}}>
                                  <Link
                                    onClick={(e) =>
                                      handleNavigate(
                                        e,
                                        result?._id?.clientcode,
                                        result?._id?.scripcode,
                                        result?._id.scripname,
                                        result?.qty
                                      )
                                    }
                                  >
                                    <FaEye />
                                  </Link>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                    </TableBody>
                  )}
                  {!isLoading && portfolioList?.data.length === 0 && (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell className="text-center" colSpan={11}>
                          No Record found
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isLoading && portfolioList?.data.length > 0 && (
                <ReactPaginate
                  previousLabel={"← Prev"}
                  nextLabel={"Next →"}
                  pageCount={Math.ceil(filteredData?.length / itemsPerPage)}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  className="mx-auto d-flex pagination mt-3"
                />
              )}
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
}
