import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css'
import ProfileDetail from './client/Pages/ProfileDetail';
import PortfolioStrategy from './client/Pages/PortfolioStrategy';
import TransactionUpload from './client/Pages/TransactionUpload';
import ManagersDesk from './client/Pages/ManagersDesk';
import PortfolioReview from './client/Pages/PortfolioReview/PortfolioReview';
import BillsInvoice from './client/Pages/BillsInvoice';
import ReferUs from './client/Pages/ReferUs';
import Support from './client/Pages/Support';
import Privateroutes from './routes/Privateroutes';
import Registeration from './client/Components/Signup/Registration';
import ViewTransaction from './client/Pages/ViewTransaction';
import Qualitative from './client/Pages/Qualitative';
import AdviceDetails from './client/Pages/AdviceDetails';
import Profile from './client/Pages/Profile';
import DashBoard from './client/Pages/DashBoard';
import Login from './client/Components/Signup/Login';
import Home from './front/pages/Home';
import Blog from './front/pages/Blog';
import Blogdetails from './front/pages/Blogdetails';
import Gallery from './front/pages/Gallery';
import GalleryDetails from './front/pages/GalleryDetails';
import Video from './front/pages/Video';
import RiskCalculator from './front/pages/RiskCalculator';
import WealthCalculator from './front/pages/WealthCalculator';
import CompoundCalculator from './front/pages/CompoundCalculator';
import Terms from './front/pages/Terms';
import Contact from './front/pages/Contact';
import Portfolio from './front/pages/Portfolio';
import OurTeam from './front/pages/OurTeam';
import TeamOne from './front/pages/Staff/TeamOne';
import TeamTwo from './front/pages/Staff/TeamTwo';
import TeamThree from './front/pages/Staff/TeamThree';
import TeamFour from './front/pages/Staff/TeamFour';
import TeamFive from './front/pages/Staff/TeamFive';
import AutoLogout from './AutoLogout';
import SiteSetting from './front/pages/SiteSetting';
import { useProfileInfoQuery } from './redux/API/portfolioApi';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from './redux/reducer/reducer';
import AlternateInvestement from './front/pages/AlternateInvestement';
import EquityAdvisory from './front/pages/EquityAdvisory';
import Privacy from './front/pages/Privacy';
import CyberSqurity from './front/pages/COMPLIANCE/CyberSqurity';
import InvestorGrievance from './front/pages/COMPLIANCE/InvestorGrievance';
import InvestorCharter from './front/pages/COMPLIANCE/InvestorCharter';
import PageChange from './PageChange';
import PageNotFound from './PageNotFound';
import ClientDetails from './client/ChanelPartner/ClientDetails';
import FranchiseDashboard from './client/Franchise/FranchiseDashboard';
import CPDetails from './client/Franchise/CPDetails';
import RMDashboard from './client/RM/RMDashboard';
import FranchiseDetails from './client/RM/FranchiseDetails';
import BusinessPlan from './client/ChanelPartner/BusinessPlan';
import CPDashboard from './client/ChanelPartner/CPDashboard';
import CPLogin from './client/ChanelPartner/CPLogin';
 

function App() {
  const {data: getProfile, error} = useProfileInfoQuery();
  const dispatch = useDispatch() 
  
  
  useEffect(() => {
    if (error) {
        if (error.data.success === false) {
            dispatch(logout());
        }
        return;
    }
    if (getProfile?.message === "jwt expired") {
        dispatch(logout());
    }
}, [getProfile, error, dispatch]);

  return (
    <BrowserRouter basename='/'>
      <PageChange/>
      <AutoLogout/>
        <Routes>
           {/*---------------------- Front Routes ---------------------*/}
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/blog' element={<Blog/>}/>
          <Route exact path='/blog/:id' element={<Blogdetails/>}/>
          <Route exact path='/gallery' element={<Gallery/>}/>
          <Route exact path='/gallery/:id' element={<GalleryDetails/>}/>
          <Route exact path='/video' element={<Video/>}/>
          <Route exact path='/risk-profile-calculator' element={<RiskCalculator/>} />
          <Route exact path='/wealth-calculator' element={<WealthCalculator/>} />
          <Route exact path='/compounding-calculator' element={<CompoundCalculator/>} />
          <Route exact path='/:slug/:id' element={<Terms/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/portfolio' element={<Portfolio/>} />
          <Route exact path='/alternate-investemnt-fund' element={<AlternateInvestement/>} />
          <Route exact path='/equity-advisory' element={<EquityAdvisory/>} />
          <Route exact path='/our-team' element={<OurTeam/>} />
          <Route exact path='/staff/ca-rajeev-ranjan' element={<TeamOne/>} />
          <Route exact path='/staff/mr-manoj-bandre/' element={<TeamTwo/>} />
          <Route exact path='/staff/mr-shashi-kant' element={<TeamThree/>} />
          <Route exact path='/staff/mr-sanket-borgaonkar' element={<TeamFour/>} />
          <Route exact path='/staff/mrs-madhu-diwakar' element={<TeamFive/>} />
          <Route exact path='/privacy' element={<Privacy/>} />
          <Route exact path='/cyber-security' element={<CyberSqurity/>} />
          <Route exact path='/investor-grievance' element={<InvestorGrievance/>} />
          <Route exact path='/investor-charter' element={<InvestorCharter/>} />
          <Route exact path='/site-setting' element={<SiteSetting/>} />
          {/*---------------------- Client Routes ---------------------*/}
          <Route element={<Privateroutes/>}>
          <Route exact path='/client' element={<DashBoard/>}/>
          <Route exact path='/client/portfoliodetail' element={<ProfileDetail/>}/>
          <Route exact path='/client/qualitative-analysis' element={<Qualitative/>}/>
          <Route exact path='/client/portfoliostrategy' element={<PortfolioStrategy/>}/>
          <Route exact path='/client/managersdesk' element={<ManagersDesk/>}/>
          <Route exact path='/client/transactionupload' element={<TransactionUpload/>}/>
          <Route exact path='/client/transactionupload/:userid/:adviseid' element={<AdviceDetails/>}/>
          <Route exact path='/client/portfolioreview' element={<PortfolioReview/>}/>
          <Route exact path='/client/billsinvoice' element={<BillsInvoice/>}/>
          <Route exact path='/client/referus' element={<ReferUs/>}/>
          <Route exact path='/client/support' element={<Support/>}/>
          <Route exact path='/client/portfoliodetail/:clientcode/:stock/:stockname' element={<ViewTransaction/>}/>
          <Route exact path='/client/profile' element={<Profile/>}/>
          {/* -------------------   Chanel Partner Route   ---------------- */}
          <Route exact path='/cp/dashboard' element={<CPDashboard/>}/>
          <Route exact path='/cp/client_details' element={<ClientDetails/>}/>
          <Route exact path='/cp/business_plan' element={<BusinessPlan/>}/>
          {/*----------------- Franchise Route -------------------*/}
          <Route exact path='/fn/dashboard' element={<FranchiseDashboard/>}/>
          <Route exact path='/fn/cp_details' element={<CPDetails/>}/>
          {/*----------------- RM Route -------------------*/}
          <Route exact path='/rm/dashboard' element={<RMDashboard/>}/>
          <Route exact path='/rm/franchise_details' element={<FranchiseDetails/>}/>
          </Route>
          <Route path='/registration' element={<Registeration/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route exact path='/cp/login' element={<CPLogin/>}/>
          <Route path='*' element={<PageNotFound/>}/>
        </Routes>
    </BrowserRouter>

  );
}

export default App;
