import React, { useContext, useState } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import "../Pages/Style/billsinvoice.css";
import "../Pages/Style/dashboard.css";
import "../../App.css";
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { SidebarContext } from '../../context/SidebarContext';
const ClientDetails = () => {
const { isSiderbaropen } = useContext(SidebarContext);
const [showLogout, setShowLogout] = useState(false);
const role = "franchise"
  return (
    <>
    <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" BillsInvoice  min-vh-100 ">
            {
              role === "franchise" && (
            <div className='mx-4 d-flex justify-content-end'>
              <select name="" id="" className='px-4 py-1 border-black-50'>
                <option value="">Select CP</option>
                <option value="">John Doe</option>
                <option value="">John Doe</option>
                <option value="">John Doe</option>
              </select>
            </div>
              )
            }
          <div className="PortfolioReviewHeader mx-4 my-4 py-2 px-2">
            <h4>Client Details</h4>
          </div>
          <div className="BillsInvoiceTable mx-4 overflow-y-hidden ">
              <MDBTable
                hover
                striped
                style={{
                  backgroundColor: "RGB(255 255 255)",
                  fontSize: "12px",
                }}
              >
                <MDBTableHead
                  style={{ backgroundColor: "#5A4344", color: "white" }}
                >
                  <tr>
                    <th scope="col">Client Name</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Email</th>
                    <th scope="col">Investment</th>
                    <th scope="col">Current Portfolio</th>
                    <th scope="col">Absolute Return</th>
                    <th scope="col">CAGR</th>
                    <th scope="col">View Detail</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>100000</td>
                        <td>200000</td>
                        <td>100%</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>100000</td>
                        <td>200000</td>
                        <td>100%</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>100000</td>
                        <td>200000</td>
                        <td>100%</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>100000</td>
                        <td>200000</td>
                        <td>100%</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                  
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
          <Footer/>
          </div>
          </div>
          {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  )
}

export default ClientDetails