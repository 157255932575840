import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../images/logo (1).png'
import '../../style/style.css'
import { useSelector } from 'react-redux'
import { IoMdArrowDropdown } from "react-icons/io";
const Navbar = () => {
const [showDropDown, setShowDropDown] = useState(false)

  const isAuthenticated = useSelector((state) => state.myreducer.isAuthenticated)


  return (
    <>
   <nav className="navbar navbar-expand-lg bg-body-tertiary position-relative  ">
  <div className="container">
    <Link className="navbar-brand me-0" to="/">
        <img src={logo} alt="Logo" width={160} height={80}  />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon  "></span>
    </button>
    <div className="collapse navbar-collapse  " id="navbarTogglerDemo02">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex  justify-content-end w-100">
        <li className="nav-item mx-1">
          <NavLink className="nav-link " aria-current="page" to="/">Home</NavLink>
        </li>
        <li className="nav-item mx-1">
          <NavLink className="nav-link " aria-current="page" to="/portfolio">Portfolio</NavLink>
        </li>
        {/* <li className="nav-item mx-1">
          <NavLink className="nav-link " aria-current="page" to="/alternate-investemnt-fund">AIF (CAT-III)</NavLink>
        </li> */}
        <li className="nav-item mx-1">
          <NavLink className="nav-link " aria-current="page" to="/equity-advisory">Equity Advisory</NavLink>
        </li>
        <li className="nav-item drop-list mx-1">
          <Link className="nav-link  " to="#" >
          Resources
          </Link>
          <ul className='drop-box position-absolute bg-white  mt-1 py-4 row' style={{zIndex: "999"}}>
           
            <div className='col-lg-6 lists position-relative'>
            <li><span className="dropdown-item mb-3">Calculator</span></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/risk-profile-calculator">Risk Profile Calculator</NavLink></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/wealth-calculator">Wealth Calculator</NavLink></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/compounding-calculator">Power Of Compounding Calculator</NavLink></li>
            </div>
            <div className='col-lg-6'>
            <li><span className="dropdown-item mb-3">Knowledge Center</span></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/blog">Blogs</NavLink></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/gallery">Gallery</NavLink></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/video">Videos</NavLink></li>
            <li><NavLink className="dropdown-item my-2 text-dark-emphasis" to="/our-team">Our Team</NavLink></li>
            </div>
          </ul>
        </li>
          <li className="nav-item mx-1">
          <NavLink className="nav-link" to="/contact">Contact us</NavLink>
        </li>
          {/* <li className='nav-item mx-4 pt-2'>
            <Link><ImSearch /></Link>
          </li> */}
          <li className='nav-item ms-2 login-btn text-white px-4 rounded-1 pt-2 pb-md-0 pb-2 position-relative'>
            {
              isAuthenticated ? (
                <Link to="/client" className='h-100 d-block text-white'>Dashboard</Link>
              )
              : (
                <>
                <Link  className='h-100 d-block text-white' onClick={() => setShowDropDown(!showDropDown)}>Login/Signup  <IoMdArrowDropdown className='ms-1 fs-3'/></Link>
                {
                  showDropDown && (
                <ul className='position-absolute login-dropdown pt-3 ps-0'>
                  <li className='ps-3 nav-item '><Link to="/login" className='p-0 nav-link'>AIF</Link></li>
                  <hr className='text-black-50 my-2'/>
                  <li className='ps-3 nav-item '><Link to="/login" className='p-0 nav-link'>Advisory</Link></li>
                </ul>
                  )
                }
                </>
              )
            }
            </li>
      </ul>
     
    </div>
  </div>
</nav>
    </>
  )
}

export default Navbar

















